import { useState, useEffect } from 'react'

import { useLead } from '@itau-loans-www/shopping/src/hooks'
import analytics from '@mobi/libraries/analytics'
import { goTo } from 'utils'

import { DDPageError } from 'utils/datadog/events'

import services from '../../services'

export const useSelectGoalCustomize = () => {
  const [loading, setIsLoading] = useState(false)
  const { changeLeadData } = useLead()

  const onSubmit = async (payload) => {
    try {
      setIsLoading(true)

      const { goal } = payload
      const requestBody = { goal }

      analytics.track('buttonClick', {
        currentPage: 'formulario-objetivo-outros',
        detail: 'click-step-objetivo-outros',
        customLayer: {
          description: goal
        }
      })

      const { next_step } = await services.putGoal({
        requestBody,
        isCustomized: true
      })

      changeLeadData(requestBody)
      goTo(next_step)
    } catch (error) {
      const payload = {
        error: error?.message,
        requestURL: error?.request?.responseURL
      }

      DDPageError({
        name: 'useSelectGoalCustomize | onSubmit',
        payload
      })
    } finally {
      setIsLoading(false)
    }
  }

  const handlePreviousStep = () => {
    changeLeadData({
      previousStep: 'select-goal'
    })
  }

  useEffect(() => {
    analytics.track('pageLoad', { currentPage: 'formulario-objetivo-outros' })

    handlePreviousStep()
  }, [])

  return { onSubmit, loading }
}
